export default {
  proxy_path: "https://evnydmd7nb.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://aswim6xw91.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyD84bsWJslpBGrsiinmyTx5aLPpbXFz0a8",
  main_company_term_id: "6",
  crm_base_url: "staging.saas.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.saas.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.saas.forwoodsafety.com",
    APP_CLIENT_ID: "pg3cutggrpkh8dmq28erobsjf",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://d1zqu2ipx7.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.saas.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
